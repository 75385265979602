<template>

  <div>

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <b-card-actions
        ref="loadingCard"
        no-actions
      >

        <div class="m-2">

          <!-- Table Top -->
          <b-row v-if="$permissions().hasPermission('create company')">

            <!-- Search -->
            <b-col
              cols="12"
              md="12"
            >
              <div class="d-flex align-items-center justify-content-end">
                <b-button
                  variant="primary"
                  @click="$router.push('/companies/add')"
                >
                  <span class="text-nowrap">New company</span>
                </b-button>
              </div>
            </b-col>
          </b-row>
        </div>
        <b-table
          ref="refCompanyListTable"
          class="position-relative users-table"
          :items="companyList"
          responsive
          stacked="sm"
          :fields="tableColumns"
          primary-key="id"
          show-empty
          empty-text="No matching records found"
        >

          <template #cell(logo)="data">
            <CompanyThumb
              :logo="data.item.logo"
              :name="data.item.name"
            />
          </template>

          <!-- Column: Created at -->
          <template #cell(created_at)="data">
            {{ data.item.created_at | formatDate }}
          </template>

          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <b-button-group>
              <b-button
                v-if="$permissions().hasPermission('edit company')"
                v-b-tooltip.hover.top="'Edit company'"
                variant="primary"
                size="sm"
                @click="edit(data.item.id)"
              >
                <feather-icon
                  icon="EditIcon"
                  class="d-inline"
                />
              </b-button>
              <b-button
                v-if="$permissions().hasPermission('delete company')"
                v-b-tooltip.hover.top="'Delete company'"
                variant="outline-danger"
                size="sm"
                @click="deleteCompany(data.item.id)"
              >
                <feather-icon
                  icon="TrashIcon"
                  class="d-inline"
                />
              </b-button>
            </b-button-group>
          </template>

        </b-table>
      </b-card-actions>
    </b-card>
    <pagination
      :event-hub="eventHub"
    />
  </div>
</template>

<script>
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import {
  BCard, BRow, BCol, BButton, BTable, VBTooltip, BButtonGroup,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import handleError from '@/views/components/errorHandler'
import CompanyThumb from '@/views/components/CompanyThumb.vue'
import Pagination from '@/layouts/components/Pagination/Pagination.vue'
import Vue from 'vue'

export default {
  components: {
    Pagination,
    BCard,
    BCardActions,
    BRow,
    BCol,
    BButton,
    BTable,
    BButtonGroup,
    CompanyThumb,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    const tableColumns = [
      { key: 'logo', sortable: false, label: 'Logo', class: 'image-column' },
      { key: 'name', sortable: true, label: 'Name' },
      { key: 'registry_number', sortable: true, label: 'Registy number' },
      { key: 'created_at', sortable: true, label: 'Created at' },
    ]
    if (
      this.$permissions().hasPermission('edit company')
      || this.$permissions().hasPermission('delete company')
    ) {
      tableColumns.push({ key: 'actions' })
    }
    return {
      tableColumns: tableColumns,
      companyList: [],
      currentPage: 1,
      perPage: 30,
      eventHub: new Vue(),
    }
  },
  created() {
    this.eventHub.$on('pageChange', this.handlePageChange)
    this.fetchCompanies()
  },
  beforeDestroy() {
    this.eventHub.$off('pageChange', this.handlePageChange)
  },
  methods: {
    handlePageChange(page) {
      this.currentPage = page
      this.fetchCompanies()
    },
    fetchCompanies() {
      if (this.$refs.loadingCard) {
        this.$refs.loadingCard.showLoading = true
      }
      this.$http.get('/v1/companies', {
        params: {
          page: this.currentPage,
          per_page: this.perPage,
        },
      })
        .then(response => {
          this.$refs.loadingCard.showLoading = false
          this.companyList = response.data.data
          this.eventHub.$emit('updateMeta', response.data.meta)
        })
        .catch(error => {
          handleError(error, this.$toast)
        })
    },
    edit(id) {
      this.$router.push({ name: 'edit-company', params: { companyId: id } })
    },
    deleteCompany(id) {
      this.$http.delete(`/v1/companies/${id}`)
        .then(() => {
          this.fetchCompanies()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Company removed',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          handleError(error, this.$toast)
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

td.image-column {
  padding: .5rem 0;
}
</style>
